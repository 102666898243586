<button
    mat-dialog-close
    class="dialog__close"
    type="button"
    [disabled]="isCloseDisabled"
>
    <mat-icon
        class="close-icon"
        svgIcon="close"
    ></mat-icon>
</button>

<div mat-dialog-title>
    <h4>Download Report</h4>
    <p class="download-report__subtitle">Click on any button to download</p>
</div>

<mat-dialog-content class="dialog-content">
    <section
        *ngIf="(multipleReportActions$ | async).length"
        class="download-report__section border-bottom"
    >
        <header class="download-report__section-title">Save All</header>
        <ul class="download-report__items">
            <li
                *ngFor="let button of (multipleReportActions$ | async)"
                class="download-report__item"
                [ngClass]="{ 'download-report__item_stretch': (multipleReportActions$ | async).length < 2 || isSmallScreen }"
            >
                <avl-download-report-button
                    [value]="button"
                    [disableButton]="isButtonsDisabled"
                    [isCentered]="(multipleReportActions$ | async).length < 2 || isSmallScreen"
                    (reportDownloaded)="onReportDownloaded($event)"
                ></avl-download-report-button>
            </li>
        </ul>
    </section>

    <section class="download-report__section">
        <header
            *ngIf="(multipleReportActions$ | async).length"
            class="download-report__section-title"
        >
            Individual Reports
        </header>
        <ul class="download-report__items individual-reports-container">
            <li
                *ngFor="let button of (individualReportActions$ | async)"
                class="download-report__item"
                [ngClass]="{ 'download-report__item_stretch': isSmallScreen }"
            >
                <avl-download-report-button
                    [value]="button"
                    [disableButton]="isButtonsDisabled"
                    [isCentered]="isSmallScreen"
                    (reportDownloaded)="onReportDownloaded($event)"
                ></avl-download-report-button>
            </li>
        </ul>
    </section>
</mat-dialog-content>
