import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EventType, FolderDetailsData, FolderDetailsDialogComponent } from '@shared/components/dialogs/folder-details-dialog/folder-details-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FolderDetails } from '@core/types';
import { filter, map } from 'rxjs/operators';
import { AlertDialogComponent } from '@shared/components/dialogs/alert-dialog/alert-dialog.component';
import { DocumentService } from '../documents';
import { UNABLE_COMMUNICATE_WITH_SERVER } from '@constants';
import { FolderStore } from './folder.store';
import { FolderApi } from '../../api';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../components/notification-center/services/notification.service';
import { LoggerService, UrlParamsService } from '@services';

@Injectable()
export class FolderService {

    private alertDialog: null | MatDialogRef<AlertDialogComponent>;

    constructor(
        private readonly dialog: MatDialog,
        private readonly folderStore: FolderStore,
        private readonly apiService: FolderApi,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly notificationCenter: NotificationService,
        private readonly logger: LoggerService,
        private readonly documentsService: DocumentService,
        private readonly urlParamsService: UrlParamsService,
    ) {
    }

    public restoreFolderIdUrlQueryParam(): void {
        const folderId = this.folderStore.getValue().id;

        if (!folderId) {
            return;
        }

        this.urlParamsService.addParams({ fid: folderId });
    }

    public async initWithEmptyValues(): Promise<void> {
        this.notificationCenter.reset();
        this.documentsService.resetState();
        this.folderStore.update({
            id: '',
            projectName: '',
            matterNumber: '',
        });
        await this.router.navigate(['/lease/upload'], {
            relativeTo: this.activatedRoute,
        });
    }

    public async create(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.apiService.create()
                .subscribe({
                    next: async (folderId) => {
                        this.folderStore.update({
                            id: folderId,
                            projectName: '',
                            matterNumber: '',
                        });
                        await this.router.navigate(['/lease/upload'], {
                            relativeTo: this.activatedRoute,
                            queryParams: { fid: folderId },
                        });

                        resolve(folderId);
                    },
                    error: (err) => {
                        this.logger.error(err);

                        setTimeout(() => {
                            const isLoginPage = location.pathname.includes('login');
                            if (!isLoginPage) {
                                this.openAlertDialog(UNABLE_COMMUNICATE_WITH_SERVER);
                            }
                        }, 300);

                        reject(err);
                    },
                });
        });
    }

    public update(folderId: string, folder: FolderDetails): void {
        this.folderStore.update(folder);
        this.apiService.updateDetails(folderId, folder).subscribe();
    }

    public getDetails(): Observable<FolderDetails> {
        return this.openDetailsDialog()
            .pipe(
                filter((result) => !!result && result.event === EventType.confirm),
                map((result) => result.data),
            );
    }

    public openDetailsDialog(folderDetails?: FolderDetails): Observable<FolderDetailsData> {
        const dialogRef = this.dialog.open(FolderDetailsDialogComponent, {
            data: folderDetails,
            panelClass: 'folder-details-dialog',
            autoFocus: false,
        });

        return dialogRef.afterClosed();
    }

    public openAlertDialog(errorData?: { title: string; message: string }): void {
        const isDialogOpened = this.alertDialog && !!this.alertDialog.componentInstance;
        if (!isDialogOpened) {
            this.alertDialog = this.dialog.open(AlertDialogComponent, {
                panelClass: 'report-dialog',
                width: '400px',
                data: errorData,
            });
        }
    }
}
