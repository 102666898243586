import { ChangeDetectionStrategy, Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { OnboardingManageService } from '../../../../onboarding/services';
import { ReportService } from '../../../services';
import { FileService } from '@services';

import { Observable } from 'rxjs';

import { ReportOptionsQuery } from '../../../store';
import { ReportAction } from '@core/types';

@Component({
    selector: 'avl-download-report-dialog',
    templateUrl: './download-report-dialog.component.html',
    styleUrls: ['./download-report-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DownloadReportDialogComponent implements OnInit {
    public isCloseDisabled = false;
    public isButtonsDisabled = false;
    public individualReportActions$: Observable<ReportAction[]>;
    public multipleReportActions$: Observable<ReportAction[]>;
    public isSmallScreen = false;

    constructor(
        private readonly dialogRef: MatDialogRef<DownloadReportDialogComponent>,
        private readonly reportService: ReportService,
        private readonly fileService: FileService,
        private readonly reportOptionsQuery: ReportOptionsQuery,
        private readonly onboarding: OnboardingManageService,
    ) {
    }

    @HostListener('window:resize', ['$event'])
    public onResize(): void {
        this.checkScreenWidth();
    }

    public ngOnInit(): void {
        this.individualReportActions$ = this.reportOptionsQuery.getSortedIndividualReportActions();
        this.multipleReportActions$ = this.reportOptionsQuery.getSortedMultipleReportActions();
        this.isCloseDisabled = this.onboarding.isActive && this.onboarding.isShowReport;
        this.checkScreenWidth();
    }

    public onReportDownloaded({ id, key, format }: ReportAction): void {
        this.isButtonsDisabled = true;
        this.reportService.downloadReportFile(key, format)
            .subscribe({
                next: (response) => {
                    if (response.status === 201) {
                        const contentLocation = response.headers.get('Content-Location');
                        const link = document.createElement('a');

                        link.href = contentLocation;
                        link.click();
                        this.onboarding.closeActiveOverlay();
                        this.reportService.markAsDownloaded(id);
                        this.dialogRef.close('success');
                    } else {
                        this.fileService.download(response);
                        this.onboarding.closeActiveOverlay();
                        this.reportService.markAsDownloaded(id);
                        this.dialogRef.close('success');
                    }
                },
                error: () => {
                    this.dialogRef.close('error');
                },
            });
    }

    private checkScreenWidth(): void {
        this.isSmallScreen = window.innerWidth <= 790;
    }
}
