<div class="page">
    <img
        class="circuits"
        src="/assets/images/background-bottom-circuits.svg"
        alt="Background circuits"
    >
    <div class="status-code-section">
        <img
            class="logo"
            src="/assets/images/avail-titles-logo.svg"
            alt="App logo"
        >
        <div class="status-code__container">
            <div class="status-code">
                <div class="status-code__char">4</div>
                <div class="status-code__char">0</div>
                <div class="status-code__char">4</div>
            </div>
            <img
                class="status-code__girl"
                src="/assets/images/girl-from-hole.png"
                alt="Girl from hole"
            >
        </div>
    </div>
    <div class="content-section">
        <div class="title-description">
            <h3 class="title">{{ title }}</h3>
            <p
                class="description"
                [innerHTML]="description"
            ></p>
        </div>
        <div class="action-buttons">
            <button
                *ngIf="isBackButtonVisible"
                class="avl-btn avl-btn--dark button"
                (click)="back()"
            >
                {{ backButtonText }}
            </button>
            <button
                class="avl-btn  avl-btn--dark avl-btn--outline button"
                (click)="showIntercom()"
            >
                Message us
            </button>
        </div>
    </div>
</div>
