import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@auth/services';
import { HttpQueryParamsService } from '@services';
import { environment } from '@env/environment';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HomeRedirectGuard implements CanActivate {

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly queryParamsService: HttpQueryParamsService,
    ) {
    }

    public canActivate(): Observable<boolean> | boolean {
        if (!environment.authGuard) {
            this.redirectToHome();

            return false;
        }

        return this.authService.user$.pipe(
            take(1),
            map((user) => {
                const isUserAuthorized = !!user;

                if (isUserAuthorized) {
                    this.redirectToHome();
                }

                return !isUserAuthorized;
            }),
        );
    }

    private redirectToHome(): void {
        const queryParams = this.queryParamsService.addRedirectToQueryParams();
        // Home page is undefined. As a result, the user will be taken to the ** route in the root appRoutes
        void this.router.navigate(['/home'], { queryParams });
    }

}
